<div *ngIf="showForm" class="main-section container flex flex-wrap bg-cover min-h-screen align-middle lg:items-center justify-center min-w-full" style="background-image: url('../../../assets/img/home-banner1.avif');">
  <div class=" z-50 flex-1 px-6 mt-20 pad:mt-10 xl:mt-10 md:mb-0 mb-14 flex ">
            <div class="block text-center m-auto">
                <h1 class="md:text-5xl text-3xl text-white font-bold">Welcome!!!!!</h1>
                <p class="md:text-lg text-sm text-white font-semibold">Sign in to access your Kargone account.</p>
                <div class="form xl:mt-10 mt-6">
                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                      <label class="block md:mb-8 mb-6">
                        <div class=" px-6">
                            <label class="inline-flex items-center jus text-gray-600 ">
                                <div class="custom-radio">
                                    <input type="radio"
                                        class="text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray "
                                        name="role" value="1" formControlName="role">
                                    <span
                                        class="custom-label ml-2 text-white font-semibold md:text-xl	text-base">Seller</span>
                                </div>
                            </label>
                            <label class="inline-flex items-center ml-6 text-gray-600 ">
                                <div class="custom-radio">
                                    <input type="radio"
                                        class="text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                                        name="role" value="0" formControlName="role">
                                    <span
                                        class="custom-label ml-2 text-white font-semibold md:text-xl	text-base">Dealer</span>
                                </div>
                            </label>
                        </div>
                    </label>
                        <label class="block md:mb-8 mb-6">
                            <input type="text" placeholder="Enter Email" name="email"
                                class="md:h-14 h-12 rounded-full md:px-8 px-6 w-full outline-0 input-login-shadow md:text-lg text-sm font-normal  border-0 text-black"
                                formControlName="email">
                        </label>
                        <label class="block mb-4">
                            <input [type]="showEye ? 'text' : 'password'" placeholder="Password" name="password"
                                class="md:h-14 h-12 rounded-full md:px-8 px-6 w-full outline-0 input-login-shadow md:text-lg text-sm font-normal  border-0 text-black"
                                formControlName="password">
                                <i class="fa eye-icon" aria-hidden="true"
                                [ngClass]="{'fa-eye-slash': !showEye,'fa-eye': showEye}" (click)="showHidePassword()"></i>
                        </label>
                   
                        <div class="block text-center  mt-12">
                            <button type="submit"
                                class="rounded-full bg-white md:py-3 py-2 md:px-20 px-12 font-semibold md:text-xl taxt-base text_primary_color cursor-pointer border border-transparent hover:border-white hover:bg-transparent hover:text-white">Sign
                                In</button>
                            <p class="md:mt-8 mt-6 md:text-base text-xs font-semibold"><a routerLink="/forgot-password"
                                    class="underline text-white hover:no-underline">Forgot Your Password ?</a></p>
                            <p class="md:mt-6 mt-4 md:text-lg text-xs font-medium text-white">Don't have a Kargone
                                account? <a href="" class="underline text-white hover:no-underline"
                                    routerLink="/signup"> Create an account</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
<!-- <div id="otp-verification" tabindex="-1" aria-hidden="true" class="flex justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-0rem)] max-h-full bg-black/75">
    <div class="relative">
      <div class="relative bg-white rounded-lg shadow bg-popup secondary-font">
        <div class="relative flex h-auto">
          <div class="relative px-6 pt-10 pb-9 shadow-xl mx-auto w-auto max-w-lg rounded-2xl bg-white">
            <div class="mx-auto flex w-full max-w-md flex-col space-y-16">
              <div class="flex flex-col items-center justify-center text-center space-y-2">
                <div class="font-semibold text-3xl">
                  <p>Email Verification</p>
                </div>
                <div class="flex flex-row text-sm font-medium text-gray-400">
                  <p>We have sent a code to your email ba**@dipainhouse.com</p>
                </div>
              </div>
  
              <div>
                <form action="" method="post">
                  <div class="flex flex-col space-y-16">
                    <div class="flex flex-row items-center justify-between mx-auto w-full">
                      <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5]">
                        <div class="w-16 h-16">
                          <input
                   
                            class="w-full h-full flex flex-col items-center justify-center text-center px-3 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                            type="text"
                            name="otp{{ index }}"
                            id="otp{{ index }}"
                            maxlength="1"
                          >
                        </div>
                      </ng-container>
                    </div>
  
                    <div class="flex flex-col space-y-5">
                      <div>
                        <button class="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-4 bg-blue-700 border-none text-white text-sm shadow-sm">
                          Verify Account
                        </button>
                      </div>
  
                      <div class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                        <p>Didn't receive the code?</p> <a class="flex flex-row items-center text-blue-600" href="http://" target="_blank" rel="noopener noreferrer">Resend</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  
<div *ngIf="!showForm" class="bg-black h-full main-section min-w-full">

</div>