import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public showEye:boolean=false;
  public showForm: boolean=false


  constructor(private fb: FormBuilder, private service: CommonService, private router: Router)  {
    this.service.showSpinner()
    if(this.service.isBrowserPlateformId()){
      let user = localStorage.getItem('user')
      if(!user){
        this.showForm = true     
        this.service.hideSpinner() 
      }else{
        this.showForm = false
        
      
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    this.intializeForm()


    // const user = JSON.parse(`${localStorage.getItem('user')}`)
    // if (this.service.isLoggedIn() && user.role == 1) {
    //   this.router.navigate(['/add-car'])
    // }
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    
  }

  intializeForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      role: ['1', Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls;
  }
 

  //login function
  login() {

    this.service.showSpinner();
    if(this.service.isBrowserPlateformId()){
      const last_url = sessionStorage.getItem('lastUrlValue')
      let tempCarId = localStorage.getItem('tempCarid')
      this.service.postApi('auths/login', this.loginForm.value).subscribe((res: any) => {
        if(res.status ==200){
          if(!res.user.isVerified && res.user.role ==1){
            this.service.hideSpinner();
            this.service.showError(res.message)
            this.router.navigate([`/verification/${res.user.id}`]);
          }
          else if((!res.user.isVerified) && res.user.role ==0){
            this.service.hideSpinner();
            this.service.showError('Please verify your email and mobile number')
            this.router.navigate([`/verification/${res.user.id}`]);
          }
          else if((!res.user.active) && res.user.role ==0){
            this.service.hideSpinner();
            this.service.showError("Oops! It seems your account hasn't been approved by the admin yet. Please allow some time for the approval process. If you continue to experience issues, feel free to contact our support team for assistance. We appreciate your patience!");
          }
  
          else if(tempCarId){
            this.addCar(Number(tempCarId),Number(res.user.id))
          
          this.service.user.next(res.user)
          let localUserData = {
            id: res.user.id,
            parentUserId:res.user.parentUserId,
            username: res.user.username,
            email:res.user.email,
            phone:res.user.phone,
            city: res.user.city,
            state: res.user.state,
            country: res.user.country,
            zipCode: res.user.zipCode,
            isAdminLoggedIn : 0,
            role : res.user.role
          }
          localStorage.setItem('user', JSON.stringify(localUserData))
          localStorage.setItem('token', res.user.token)
          this.service.showSuccess(res.message);
          this.service.hideSpinner();
        
  
          if (res.user.role == 1) {
            if(last_url){
              this.router.navigate([last_url]);
            }else{
              this.router.navigate(['seller/car-list-new']);
            }
          }
          else {
            if(last_url){
              this.router.navigate([sessionStorage.getItem('lastUrlValue')]);
            }else{
              this.router.navigate(['dealer/dealer-performance'])
            }
           
          }
        
      }
      else{
        this.service.user.next(res.user)
        let localUserData = {
          id: res.user.id,
          parentUserId:res.user.parentUserId,
          username: res.user.username,
          email:res.user.email,
          phone:res.user.phone,
          city: res.user.city,
          state: res.user.state,
          country: res.user.country,
          zipCode: res.user.zipCode,
          isAdminLoggedIn : 0,
          role : res.user.role,
        }
        localStorage.setItem('user', JSON.stringify(localUserData))
        localStorage.setItem('token', res.user.token)
        this.service.showSuccess(res.message);
        this.service.hideSpinner();
        if (res.user.role == 1) {
          if(last_url){
            this.router.navigate([last_url]);
          }else{
            this.router.navigate(['seller/car-list-new']);
          }
            
        }
        else {
      
          if(last_url){
            this.router.navigate([last_url]);
          }else{
            this.router.navigate(['dealer/dealer-performance'])
          }
        }
      }
        }
        else{
          this.service.showError(res.message)
          this.service.hideSpinner();
        }
  
      }, (error) => {
        this.service.hideSpinner();
      })
    }

  }

  addCar(carId:number,userId:number){
    this.service.showSpinner();
    this.service.postApi('tempcars/update-temp-car', {carId:carId,userId:userId}).subscribe((res: any) => {
      this.service.hideSpinner();
      if(this.service.isBrowserPlateformId()){

        localStorage.removeItem('tempCarid')
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }

  showHidePassword(){
    this.showEye = !this.showEye
  }
}
