<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name"
            class="max-w-full sm:inline-block hidden lg:h-[500px] h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full sm:pb-[4%]	flex flex-wrap 
    sm:items-end items-center justify-center">
        <div class="container mx-auto  ">
            <div class="w-full mx-auto z-50  flex-1 px-6 ">
                <div class="block text-center">
                    <div class="inline-block px-10 py-2 rounded-full bg-white mb-6 text-[#004AAD] font-semibold xl:text-xl text-base"
                        *ngIf="carPlateNo && carPlateNo!='null' && carPlateNo!=' '">
                        {{carPlateNo}}</div>
                    <h1 class="xl:text-5xl text-4xl text-white font-bold ">{{carName}}</h1>
                    <div class="text-center text-white xl:text-2xl text-base mt-4 ">
                        <div
                            class="relative inline-block sm:px-10 px-4 after:absolute after:xl:w-[8px] after:xl:h-[8px] after:w-[5px] after:h-[5px] after:rounded-full after:bg-white after:right-0 after:xl:top-[13px] after:top-[9px]">
                            {{modelYear}} 
                        </div>
                        <div class="relative inline-block sm:px-10 px-4"
                            [ngClass]="{'after:absolute after:xl:w-[8px] after:xl:h-[8px] after:w-[5px] after:h-[5px] after:rounded-full after:bg-white after:-right-[0px] after:md:top-[13px] after:top-[9px]': fuelType}">
                            {{modelName}}</div>
                        <div class="inline-block sm:px-10 px-4"> {{fuelType}}</div>
                    </div>
                    <div class="inline-block mt-4 px-10 py-2 rounded-full bg-white mb-6 text-[#004AAD] font-semibold xl:text-xl text-base"
                        *ngIf="vinNumber && vinNumber!='null'">
                        {{vinNumber}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<section *ngIf="carQuestions">
    <form [formGroup]="carForm" class="bg-white">
        <div class="w-full mx-auto">
            <div class=" bg-white mileage-car-box b-10 md:flex md:flex-wrap justify-between px-4 lg:px-0 py-10 lg:py-0" *ngIf="mileageAndColor?.length>0">
                <div class="lg:p-10  lg:w-5/12  w-full bg-white">
                    <div class="title-heading pb-1 lg-3 leading-none text-center lg:mb-6 mb-4 lg:text-center">
                        <h2 class="xl:text-3xl text-xl  font-bold leading-none text_primary_color">{{mileageAndColor[0]?.question}}</h2>
                        <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    </div>
                    <div class="w-full mb-6 text-center">
                        <input type="text" name="car-milage" [placeholder]="mileageAndColor[0]?.questionPlaceHolder"
                            class="mt-1 md:h-14 h-12 w-8/12  md:w-7/12 inline-block  bg-white  rounded-lg  placeholder-[#000] text-black md:px-8 px-4 md:text-base text-sm input-shadow  text-center outline-0 font-[500]"
                            formControlName="mileage" (keypress)="numberOnly($event)"
                            [ngClass]="{'border-red-600': f['mileage'].invalid && isSubmit,' border-0' : f['mileage'].valid || !isSubmit}" (keypress)="numberOnly($event)" />
                    </div>
                </div>
                <div class="lg:p-10 lg:w-5/12 w-full bg-white mt-10 md:mt-0">
                    <div class="title-heading pb-1 lg-3 leading-none text-center lg:mb-6 mb-4 lg:text-center">
                        <h2 class="xl:text-3xl text-xl  font-bold leading-none text_primary_color">{{mileageAndColor[1]?.question}}</h2>
                        <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    </div>
                    <div class="w-full mb-6 " (click)="toggleDropDown()">

                        <div
                            class="dropdown mt-1 mx-auto block md:h-14 h-12  w-8/12  md:w-7/12  bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow  text-center" 
                            [ngClass]="{'border border-red-600': f['color'].invalid && isSubmit,' border-0' : f['color'].valid || !isSubmit}">
                            <label tabindex="0"
                                class="btn border-none m-1 appearance-none bg-white hover:bg-white h-auto min-h-min md:mt-6 mt-4 text-black">
                                {{color ? color : mileageAndColor[1]?.questionPlaceHolder}}
                                <span><img src="assets/img/down-arrow.png" alt="head1"></span>
                            </label>
                            <ul tabindex="0"
                                class="dropdown-content z-[1] menu p-2 shadow !relative bg-white mt-1 text-base "
                                *ngIf="isDropdownOpen">
                                <li class="mb-2 hover:bg-[#1559B3] hover:text-white cursor-pointer"
                                    *ngFor="let option of mileageAndColor[1]?.options;" [value]="option"
                                    (click)="selectColor(option)">{{option}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- DO NOT REMOVE THIS CODE -->

             <!-- <div class="bg-white mileage-car-box b-10 md:flex md:flex-wrap justify-between px-4 lg:px-0 py-10 lg:py-0" *ngIf="mileageAndColor?.length > 0">
                <ng-container *ngFor="let item of mileageAndColor; let i = index">
                    <div class="lg:p-10 lg:w-5/12 w-full bg-white">
                        <div class="title-heading pb-1 lg-3 leading-none text-center lg:mb-6 mb-4 lg:text-center">
                            <h2 class="xl:text-3xl text-xl font-bold leading-none text_primary_color">{{ item.question }}</h2>
                            <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                            <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                            <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                        </div>
                        <div class="w-full mb-6 text-center">
                            <input type="text" name="car-milage" [placeholder]="item.questionPlaceHolder"
                                class="mt-1 md:h-14 h-12 w-8/12 md:w-7/12 inline-block bg-white rounded-lg placeholder-[#000] text-black md:px-8 px-4 md:text-base text-sm input-shadow text-center outline-0 font-[500]"
                                [formControlName]="item.specialQuestionName" (keypress)="numberOnly($event)"
                                [ngClass]="{ 'border-red-600': f[item.specialQuestionName].invalid && isSubmit, 'border-0': f[item.specialQuestionName].valid || !isSubmit }" />
                        </div>
                    </div>
                </ng-container>
            </div>  -->
            
        </div>
        <div class="max-w-screen-xl	mx-auto ">
            <div class="info-car-detail-form  md:text-center" *ngFor="let item of carQuestions;let k=index">
                <div *ngIf="item.category.specialCategoryName != 'Mileage and Color'">
                    <div class="text-center w-full" *ngIf="item.category.categoryName != 'Referral Sources'">
                        <div class="inline-block  px-20 bg-white mb-8" [ngClass]="{'lg:-mt-20 mt-10': (k==0 && mileageAndColor), 'mt-4': k==0 && !mileageAndColor}">
                            <img alt="car image" [src]="item.category.image">
                        </div>
                    </div>
                    <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center " *ngIf="item.category.categoryName != 'Referral Sources'">
                        <h2 class="xl:text-4xl text-3xl  font-bold leading-none text_primary_color">
                            {{item.category.categoryName }}</h2>
                        <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                        <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    </div>
                    <div class="step-1 mb-12 px-4 md:px-0" *ngFor="let question of item.questions; let i=index">
                        <h3 class="xl:text-2xl text-xl font-bold  inline-flex xl:items-center mb-4 relative pl-10 md:pl-0 leading-none"
                            [ngClass]="{'text-red-500': (f[convertToString(question.id)].invalid && isSubmit),'text_primary_color' : (f[convertToString(question.id)].valid || !isSubmit)}">
                            <span
                                class="border border-dashed border-primary-color rounded-full md:w-9 md:h-9 w-6 h-6 inline-flex items-center justify-center leading-none  xl:mr-6 mr-4  text-[#373737] md:relative absolute left-0 top-1 md:top-0 md:text-xl text-base">{{item.category.specialCategoryName == 'Referral Sources' ? lastIndex :(i+1)}}</span>
                            {{ question.question }}
                        </h3>
                        <!-- for radio  -->
                        <div *ngIf="question.type == 'radio'">
                            <div class="mb-6 pl-10 md:pl-0">
                                <label class="inline-flex items-center custom-radio-blue mr-8"
                                    *ngFor="let option of (question.options); let j=index">
                                    <input checked type="radio" value="1" [id]="question.id+'-' + i + '-' + j"
                                        [name]="question.id" [value]="option" [formControlName]="question.id">
                                    <label
                                        class="ml-2 xl:text-lg md:text-base text-sm font-medium text-black custom-label"
                                        [for]="question.id + '-' + i + '-' + j">{{ option }}</label>
                                </label>
                                <div
                                    *ngIf="(question.isSubOption==1 && carForm.get(convertToString(question.id) )?.value !='No' && carForm.get(convertToString(question.id) )?.value !='')">
                                    <div class="mt-6 pl-6 md:pl-0 flex flex-wrap md:justify-center gap-4 mx-auto"
                                        *ngIf="question.subType == 'checkbox'">
                                        <div class="pl-6 md:pl-0 flex flex-wrap md:justify-center gap-4 mx-auto">
                                            <div class="inline-flex items-center checkbox-custom-large lg:mr-4"
                                                *ngFor="let option of question.subOptions; let j = index">
                                                <input type="checkbox" [id]="'checkbox-' + question.id + '-' + j"
                                                    [checked]="carForm.get('subOption-' +question.id)?.value.includes(option)"
                                                    (change)="updateCheckboxType(option, ('subOption-' +question.id))">
                                                <label for="state"
                                                    class="checkbox-box font-medium xl:text-lg md:text-base text-sm text-black"
                                                    [for]="'checkbox-' + question.id + '-' + j">{{option}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6 pl-10 md:pl-0" *ngIf="question.subType =='text'">
                                        <input type="text" name="car-color"
                                            class="mt-1 md:h-14 h-12  w-10/12 lg:w-6/12 sm:w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"
                                            [formControlName]="question.id" [placeholder]="question.questionPlaceHolder" />
                                    </div>
                                    <div class="mb-6 pl-10 md:pl-0" *ngIf="question.subType =='number'">
                                        <input type="text" name="car-color"
                                            class="mt-1 md:h-14 h-12  w-10/12 lg:w-6/12 sm:w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"
                                            [formControlName]="question.id" [placeholder]="question.questionPlaceHolder" (keypress)="numberOnly($event)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-6 pl-10 md:pl-0"
                                *ngIf="(question.descriptionField === 1  && carForm.get(convertToString(question.id))?.value != 'No' && carForm.get(convertToString(question.id))?.value !='')">
                                <textarea name="car-color"
                                    class="mt-1 md:h-32 h-12 w-10/12 lg:w-6/12 sm:w-11/12 inline-block bg-white rounded-lg placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-left font-[500]"
                                    id="description-{{i}}" [placeholder]="question.descriptionPlaceHolder"
                                    [formControlName]="'description-'+question.id">
                                </textarea>
                            </div>

                        </div>
                        <!-- input type text -->
                        <div class="mb-6 pl-10 md:pl-0" *ngIf="question.type =='text'">
                            <input type="text" name="car-color"
                                class="mt-1 md:h-14 h-12  w-10/12 lg:w-6/12 sm:w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"
                                [formControlName]="question.id" [placeholder]="question.questionPlaceHolder" />
                        </div>
                        <div class="mb-6 pl-10 md:pl-0" *ngIf="question.type =='number'">
                            <div *ngIf="question.isSpecialQuestion">
                                <input type="text" name="car-color"
                                class="mt-1 md:h-14 h-12  w-10/12 lg:w-6/12 sm:w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"
                                [formControlName]="question.id" [placeholder]="question.questionPlaceHolder" (keypress)="numberOnly($event)" (focusout)="formatSellingPrice(f[convertToString(question.id)].value,question.id)"/>
                            </div>
                            <div *ngIf="!question.isSpecialQuestion">
                                <input type="text" name="car-color"
                                class="mt-1 md:h-14 h-12  w-10/12 lg:w-6/12 sm:w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"
                                [formControlName]="question.id" [placeholder]="question.questionPlaceHolder" (keypress)="numberOnly($event)"/>
                            </div>
                        
                        </div>
                        <!-- for checkbox -->
                        <div *ngIf="question.type === 'checkbox'">
                            <div class="mb-10 pl-6 md:pl-0 flex flex-wrap md:justify-center gap-4 mx-auto"
                            >
                            <div class="inline-flex items-center checkbox-custom-large lg:mr-4" *ngFor="let option of question.options; let j = index">
                                <input type="checkbox" [id]="'checkbox-' + question.id + '-' + j"
                                    [checked]="carForm.get(convertToString(question.id) )?.value.includes(option)"
                                    (change)="updateCheckboxType(option, convertToString(question.id))">
                                <label class="checkbox-box font-medium xl:text-lg md:text-base text-sm text-black"
                                    [for]="'checkbox-' + question.id + '-' + j">{{option}}</label>
                            </div>
                            </div>
                            <div class="mb-6 pl-10 md:pl-0"
                                *ngIf="question.descriptionField === 1 && carForm.get(convertToString(question.id) )?.value != 'None' && carForm.get(convertToString(question.id) )?.value != '' && item.category.specialCategoryName != 'Referral Sources'">
                                <textarea name="car-color"
                                    class="mt-1 md:h-32 h-12 w-10/12 lg:w-6/12 sm:w-11/12 inline-block bg-white rounded-lg placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-left font-[500]"
                                    id="description-{{i}}" [placeholder]="question.descriptionPlaceHolder"
                                    [formControlName]="'description-'+question.id">
                                 </textarea>
                            </div>
                            <div class="mb-6 pl-10 md:pl-0"
                            *ngIf="question.descriptionField === 1 && carForm.get(convertToString(question.id) )?.value.includes('Others') && item.category.specialCategoryName == 'Referral Sources'">
                            <textarea name="car-color"
                                class="mt-1 md:h-32 h-12 w-10/12 lg:w-6/12 sm:w-11/12 inline-block bg-white rounded-lg placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-left font-[500]"
                                id="description-{{i}}" [placeholder]="question.descriptionPlaceHolder"
                                [formControlName]="'description-'+question.id"
                                [ngClass]="{'border border-red-600': (f['description-'+question.id].invalid && isSubmit ),' border-0' : (f['description-'+question.id].valid && !isSubmit)}">
                             </textarea>
                        </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <!-- Seller Info  -->
         <div *ngIf="!this.user">
            <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                <h2 class="lg:text-4xl md:text-3xl text-2xl font-bold leading-none text_primary_color">Seller Info</h2>
                <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>

                <div class="container mx-auto mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="mb-8">
                        <div class="md:text-center text-left pl-4 md:pl-0">
                            <span                                                                                                                                       
                               class="border border-dashed border-primary-color rounded-full md:w-9 md:h-9 w-6 h-6 inline-flex items-center justify-center leading-none  xl:mr-6 mr-4 font-semibold   text-[#373737]   md:text-xl text-base"> 1 </span>
                            <h3 class="xl:text-2xl text-xl font-bold inline-flex xl:items-center mb-4  pl-0 leading-none text_primary_color ">
                                Please enter your name.
                            </h3>
                        </div>
                      <div>
                          
                        <input formControlName="userName" type="text" placeholder="Enter your name" class="mt-1 md:h-14 h-12 w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"/>
                      </div>
                       
                    </div>

                    <div class="mb-8 ">
                        <div class="md:text-center text-left pl-4 md:pl-0">
                            <span
                            class="border border-dashed border-primary-color rounded-full md:w-9 md:h-9 w-6 h-6 inline-flex items-center justify-center leading-none  xl:mr-6 mr-4 font-semibold   text-[#373737]  md:text-xl text-base"> 2 </span>
                            <h3  [class]="carForm.get('email')?.invalid  && isSubmit ? 'text-red-500': 'text_primary_color'"  class="xl:text-2xl text-xl font-bold inline-flex xl:items-center mb-4 pl-0 leading-none ">
                                Please enter your email.
                            </h3>
                        </div>
                      <div>
                          
                        <input formControlName="email" type="text" placeholder="Enter your email" class="mt-1 md:h-14 h-12 w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"/>
                      </div>

                    </div>
                    <div class="mb-8 ">
                        <div class="md:text-center text-left pl-4 md:pl-0">
                            <span
                            class="border border-dashed border-primary-color rounded-full md:w-9 md:h-9 w-6 h-6 inline-flex items-center justify-center leading-none  xl:mr-6 mr-4 font-semibold   text-[#373737] md:text-xl text-base"> 3 </span>
                            <h3   [class]="carForm.get('phone')?.invalid  && isSubmit ? 'text-red-500': 'text_primary_color'"  class="xl:text-2xl text-xl font-bold inline-flex xl:items-center mb-4 relative pl-0 leading-none ">
                                Please enter your phone number.
                            </h3>
                        </div>
                      <div>
                          
                        <input formControlName="phone"  type="number" placeholder="Enter your phone no" class="mt-1 md:h-14 h-12  w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"/>
                      </div>
                       
                    </div>

                    <div class=" mb-8">
                        <div class="md:text-center text-left pl-4 md:pl-0">
                            <span
                            class="border border-dashed border-primary-color rounded-full md:w-9 md:h-9 w-6 h-6 inline-flex items-center justify-center leading-none  xl:mr-6 mr-4 font-semibold   text-[#373737] md:text-xl text-base"> 4 </span>
                            <h3  [class]="carForm.get('zipCode')?.invalid  && isSubmit ? 'text-red-500': 'text_primary_color'"  class="xl:text-2xl text-xl font-bold inline-flex xl:items-center mb-4 pl-0 leading-none">
                                Please enter your zipcode.
                            </h3>
                        </div>
                      <div>
                          
                        <input formControlName="zipCode" type="number" placeholder="Enter your zipcode" class="mt-1 md:h-14 h-12 w-11/12 inline-block bg-white  rounded-lg  placeholder-[#777] text-black md:px-8 px-4 md:text-base text-sm input-shadow outline-0 border-0 text-center font-[500]"/>
                      </div>
                       
                    </div>

                </div>
            </div>
         </div>
        


        <div class="max-w-[1340px] mx-auto">
            <div class="upload-car-section px-4">
                <div class="title-heading pb-1 lg-3 leading-none text-center mb-8 lg:text-center">
                    <h2 class="lg:text-4xl md:text-3xl text-2xl font-bold leading-none text_primary_color">Upload
                        Vehicle
                        Photos</h2>
                    <span class="w-28 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                    <span class="w-1.5 h-1 bg_primary mr-2 inline-block"></span>
                </div>
                <div class="title-heading pb-1 lg-3 leading-none text-justify mb-8">
                    <p class="mt-6 md:text-lg text-base text-black mb-4">1. To get the best possible offer for your
                        vehicle, please upload all the 12 pictures of your vehicle.</p>
                    <p class="mt-2 md:text-lg text-base text-black mb-4">2. If you do not have pictures of your vehicle
                        you can click on “continue” to create an account with us and upload the photos of your vehicle
                        later.</p>
                    <!-- <p class="mt-2 md:text-lg text-base text-black font-semibold mb-4">3. Please note that until your
                        vehicle pictures are uploaded your vehicle will not be listed on our platform for dealers to
                        place their offers.</p> -->
                    <p class="mt-2 md:text-lg text-base text-black mb-4">3. To upload pictures of your vehicle please
                        click on the desired picture box below and select the vehicle picture.</p>
                    <p class="mt-2 md:text-lg text-base text-black">4. To replace or change an existing picture of your
                        vehicle please click on the desired picture box below and select the desired picture of the
                        vehicle.</p>
                        <p class="mt-2 md:text-lg text-base text-black">5. Please upload a picture of your valid CarMax in-store offer to be eligible for the $200 CarMax offer..</p>
                </div>
                <div class="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 mb-10 mt-20">

                    <div class="rounded-lg border-dashed border-2 border-primary-color bg-[#E4EFFF] flex items-center justify-center overflow-hidden cursor-pointer h-[208px]"
                    (click)="fileInput.click()" *ngFor="let corner of corners;let i=index">
                    <div>
                        <input type="file" [id]="corner.name" [disabled]="corner.isLoading" (change)="onImageSelected($event,corner.name,i)"
                            style="display: none" accept="image/*" #fileInput />
                    </div>

                    <div class="group">
                        <div
                            class="box-photos-up z-10 text-center img-block relative flex items-center justify-center overflow-hidden after:absolute after:w-full after:h-full after:top-0 after:left-0  after:rounded-lg after:z-0 rounded-lg">
                            <label class="cursor-pointer">
                                <!-- -->
                                <div *ngIf="!corner.isUploaded || corner.isLoading" class="absolute group-hover:opacity-0  top-0 left-0 z-50 w-full h-full flex items-center justify-center" >
                                    <img src="assets/img/upload-white.svg" alt="upload" *ngIf="!corner.isLoading"
                                         class="inline-block  mx-auto lg:w-20 w-14 relative -top-10" />
                                    <img src="assets/img/greyloader.gif" alt="upload" *ngIf="corner.isLoading"
                                    class="inline-block   mx-auto  lg:w-20 w-14"   />
                                </div>
                                
                                <div class="">
                                    <img [src]="images[corner.name]" alt="Uploaded Image" class="max-h-[208px]"
                                        [ngClass]="{'group-hover:blur-none blur-sm': !corner.isUploaded}" />
                                    <div  *ngIf="!corner.isLoading && !corner.isUploaded" class="text-lg font-bold group-hover:opacity-0 text-white mt-2 absolute  z-30 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 "
                                        style="width: max-content;">{{ corner.label }}</div>
                                </div>

                            </label>
                        </div>
                    </div>
                </div>

                </div>
            </div>


            <div class="mt-20 mb-10 text-center px-20 md:px-0">
                <p class="text-[#3A3F4D] text-base">By continuing, you agree to our <a
                        class="text-[#004AAD] underline hover:no-underline" [routerLink]="['/term-condition']"> Term &
                        Conditions</a> and <a [routerLink]="['/privacy-policy']"
                        class="text-[#004AAD] underline hover:no-underline"> Privacy Policy</a>.</p>
            </div>
            <div class="flex justify-center  pb-8 px-4 md:px-0">
                <button type="button"
                    class="text-white bg_primary font-medium rounded-full  text-lg px-12 py-2 text-center border border-primary-color outline-0 hover:bg-white hover:text-[#004AAD]  cursor-pointer"
                    (click)="onSubmit()">Continue</button>
            </div>
        </div>
    </form>
</section>