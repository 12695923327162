<div class="inner-banner bg-cover z-0 relative">
    <div class="inner-banner-images text-center">
        <img src="assets/img/inner-banner.png" alt="Page Name" class="max-w-full sm:inline-block hidden lg:h-auto h-96 object-cover">
        <img src="assets/img/inner-mobile.png" alt="Page Name" class="w-full sm:hidden inline-block">
    </div>
    <div class="absolute bg_primary-900 top-0 left-0 w-full z-1 h-full	flex flex-wrap items-center justify-center" >
        <div class="container mx-auto  ">
            <div class="max-w-full mx-auto z-50 px-6 ">
                <div class="block text-center">
                    <h1 class="md:text-6xl text-4xl text-white font-bold ">Contact Us</h1>       
                </div>
            </div>
        </div>
    </div>
</div>
<section class="relative lg:py-16 py-10">
    <div class="container max-w-screen-xl mx-auto px-4 lg:px-20">
        <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-6 text-center">
            <div class="box-shadow p-6">
                <div class="icon text-center border-dashed border border-[#004AAD] rounded-full w-12 h-12 mt-1 inline-flex items-center justify-center p-2">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#00459f" width="30px">
                        <g>
                            <path d="M49.3,96.2c-1.5-1.3-3-2.4-4.4-3.8c-7.9-7.8-15-16.3-20.7-26c-3.6-6.2-6.5-12.7-8-19.8c-3.6-16.7,4.6-33.2,20.2-40.1
                                C50.5,0.4,67.5,4.7,77.2,17c5.5,7,8,15.1,7.5,24c-0.4,6.5-2.4,12.6-5.1,18.5C75.1,68.9,69.1,77.2,62.2,85c-3,3.3-6.2,6.5-9.3,9.7
                                c-0.6,0.6-1.4,1.1-2.1,1.6C50.3,96.2,49.8,96.2,49.3,96.2z M69.2,38.4c0-10.6-8.6-19.2-19.2-19.2c-10.6,0-19.3,8.7-19.2,19.3
                                c0.1,10.6,8.7,19.2,19.3,19.2C60.7,57.6,69.3,49,69.2,38.4z"/>
                        </g>
                        </svg>
               
                </div>
                <h3 class="text-[#1558B2] text-2xl mt-4 mb-2 font-bold leading-none">Address</h3>
                <p class="text-lg text-[#424242]">{{cmsData.address}}
                   </p>
            </div>
            <div class="box-shadow p-6">
                <div class="icon text-center border-dashed border border-[#004AAD] rounded-full w-12 h-12 mt-1 inline-flex items-center justify-center p-2">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#00459f" width="30px">
                        <g>
                            <path d="M23.1,3.7c1.7,0.4,3.4,0.9,4.8,2.1c0.3,0.2,0.5,0.4,0.8,0.6c4.1,4.1,8.2,8.2,12.3,12.3c3.3,3.4,3.7,8.2,1,11.7
                                c-0.9,1.2-2.1,2.1-3.6,2.6c-2.6,0.9-4.6,2.5-5.7,5.1c-0.7,1.7-0.8,3.3-0.3,5.1c1.3,4.7,3.8,8.7,7,12.3c3.7,4.3,8,7.9,13.1,10.4
                                c2.1,1,4.3,1.9,6.8,1.6c3.1-0.4,5.5-2,6.9-4.9c0.6-1.3,1.2-2.7,2.1-3.7c3-3.4,8.2-3.8,11.8-0.9c0.4,0.3,0.8,0.7,1.2,1.1
                                c4,4,8,8.1,12,12c4,3.8,3.6,9.8,0,13.4c-2.6,2.6-5.3,5.3-7.9,7.9C83,95,80,96.1,76.6,96.2c-4.8,0.2-9.3-1.1-13.7-2.8
                                c-4.6-1.8-8.9-4.1-13-6.8C37.9,78.9,27.7,69.3,19,58.1c-4.7-6-8.8-12.5-11.7-19.6c-1.7-4.1-3-8.4-3.3-12.9c0-0.3-0.1-0.5-0.1-0.8
                                c0-0.4,0-0.7,0-1.1c0.1-0.4,0.1-0.9,0.2-1.3c0.3-3.1,1.4-5.9,3.6-8.1c2.2-2.1,4.3-4.2,6.4-6.4c1.9-2,3.8-3.7,6.6-4.3
                                C21.4,3.7,22.3,3.7,23.1,3.7z"/>
                        </g>
                    </svg>
               
                </div>
                <h3 class="text-[#1558B2] text-2xl mt-4 mb-2 font-bold leading-none">Phone</h3>
                <p class="text-lg text-[#424242]">+1 {{formatPhoneNumber(cmsData.phone)}}</p>
            </div>
            <div class="box-shadow p-6">
                <div class="icon text-center border-dashed border border-[#004AAD] rounded-full w-12 h-12 mt-1 inline-flex items-center justify-center p-2">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve" fill="#00459f" width="30px">
                    <g>
                        <path d="M6.7,26c0.2-0.7,0.5-1.3,0.7-2.1c8.7,8.9,17.3,17.6,25.7,26.2c-8.3,8.5-16.9,17.2-25.6,26.2c-0.3-0.9-0.6-1.6-0.8-2.2
                            C6.7,58,6.7,42,6.7,26z"/>
                        <path d="M10.9,20.1c1.3-0.2,2.4-0.5,3.5-0.5c4.9-0.1,9.8,0,14.7,0c18.7,0,37.5,0,56.2,0c1.2,0,2.4,0.3,3.5,0.5c0,0.1,0,0.2,0,0.3
                            c-0.2,0.2-0.4,0.4-0.5,0.6C77.5,31.7,66.6,42.6,55.8,53.4c-2.3,2.3-5,3.2-8.2,2.1c-1.2-0.4-2.3-1.1-3.2-2
                            c-11-10.9-22-21.8-32.9-32.7C11.4,20.6,11.2,20.5,10.9,20.1z"/>
                        <path d="M11.2,80c8.7-8.9,17.3-17.6,26.2-26.7c0.4,0.6,0.7,1,1,1.4c1.3,1.3,2.5,2.6,3.9,3.7c5.2,4,12.1,3.5,16.9-1.2
                            c1.2-1.1,2.3-2.3,3.6-3.6C71.4,62.4,80,71.2,88.6,80c-0.8,0.1-1.9,0.5-3,0.5c-5.4,0.1-10.9,0-16.3,0c-18.2,0-36.4,0-54.6,0
                            C13.5,80.4,12.3,80.1,11.2,80z"/>
                        <path d="M92.3,76.1C83.7,67.4,75.1,58.6,66.7,50c8.4-8.6,17-17.4,25.7-26.2c0.7,1.2,0.9,2.3,0.9,3.5c0,8.1,0,16.3,0,24.4
                            c0,7,0,14,0,21c0,1.1-0.3,2.2-0.5,3.4C92.6,76.1,92.5,76.1,92.3,76.1z"/>
                    </g>
                    </svg>
                </div>
                <h3 class="text-[#1558B2] text-2xl mt-4 mb-2 font-bold leading-none">Email</h3>
                <p class="text-lg text-[#424242]"><a class="text-[#424242]" [href]="'mailto:'+cmsData.email">{{cmsData.email}}</a></p>
            </div>
          </div>
    </div>
    <div class="container max-w-7xl	mt-14 mx-auto box-shadow box-shadow-none lg:p-8">                
        <div class="lg:flex flex-wrap">
            <div class="lg:w-5/12 w-full">
                <div class="img-block">
                    <img src="assets/img/contact-img.png" alt="Contact" class="hidden lg:block">
                    <img src="assets/img/m-contact-img.png" alt="Contact" class="block lg:hidden w-full	">
                </div>
            </div>
            <div class="lg:w-7/12 w-full mt-10 lg:mt-0">
                <div class="block-contact lg:px-10 px-4">
                    <h2 class="text-4xl	text-black tracking-wide font-bold mb-2">Get In Touch</h2>
                    <p class="lg:text-lg text-base text-[#3A3F4D] lg:w-3/4">Please fill in the details and we will get back to you within 24 hours.</p>
                    <form class="mt-6" [formGroup]="contactForm" (ngSubmit)="submitForm()">
                        <div class="lg:grid lg:grid-cols-2 lg:gap-4">
                            <div class="relative z-0 w-full mb-6 group">
                                <input type="text" formControlName="firstName" placeholder="Enter First Name" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-lg placeholder-[#777777] md:px-4 px-4 md:text-base text-sm outline-0 border border-[#CDD3D9] focus:border-[#CDD3D9] focus:shadow-none" />
                                <div *ngIf="contactForm.get('firstName')?.errors?.required && contactForm.get('firstName')?.touched  || contactForm.get('firstName')?.errors?.required && !isFormValid "  class="text-red-500 text-sm mt-1">First name is required</div>
                            </div>
                            <div class="relative z-0 w-full mb-6 group">
                                <input type="text" formControlName="lastName" placeholder="Enter Last Name" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-lg placeholder-[#777777] md:px-4 px-4 md:text-base text-sm outline-0 border border-[#CDD3D9] focus:border-[#CDD3D9] focus:shadow-none" />
                                <div *ngIf="contactForm.get('lastName')?.errors?.required && contactForm.get('lastName')?.touched  || contactForm.get('lastName')?.errors?.required && !isFormValid " class="text-red-500 text-sm mt-1">Last name is required</div>
                            </div>
                        </div>
                        <div class="grid lg:grid-cols-2 lg:gap-4">
                            <div class="relative z-0 w-full mb-6 group">
                                <input type="email" formControlName="email" placeholder="Enter Email" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-lg placeholder-[#777777] md:px-4 px-4 md:text-base text-sm outline-0 border border-[#CDD3D9] focus:border-[#CDD3D9] focus:shadow-none" />
                                <div *ngIf="contactForm.get('email')?.errors?.required && contactForm.get('email')?.touched  || contactForm.get('email')?.errors?.required && !isFormValid " class="text-red-500 text-sm mt-1">Email is required</div>
                                <div *ngIf="contactForm.get('email')?.errors?.email && contactForm.get('email')?.touched || contactForm.get('email')?.errors?.email && !isFormValid " class="text-red-500 text-sm mt-1">Invalid email format</div>
                            </div>
                            <div class="relative z-0 w-full mb-6 group">
                                <input type="text" formControlName="phoneNumber" placeholder="Enter Mobile Number" class="mt-1 md:h-14 h-12 block w-full bg-white rounded-lg placeholder-[#777777] md:px-4 px-4 md:text-base text-sm outline-0 border border-[#CDD3D9] focus:border-[#CDD3D9] focus:shadow-none"  (keypress)="numberOnly($event)"/>
                            </div>
                        </div>
                        <div class="relative z-0 w-full mb-6 group">
                            <textarea formControlName="message" placeholder="Please write here..." class="h-32 mt-1 block w-full bg-white rounded-lg placeholder-[#777777] md:px-4 px-4 md:text-base text-sm outline-0 border border-[#CDD3D9] focus:border-[#CDD3D9] focus:shadow-none"></textarea>
                        </div>

                         <!-- Terms And Condition Checkbox -->
                    <label class="block mt-4 pl-6 w-full h-auto checkbox border-none text-justify">
                        <div class="checkbox-custom">
                            <input type="checkbox"  formControlName="tnc"
                                class="checked:bg-blue-500 mr-2 border-[#004AAD]" />
                            <span class="checkbox-box"></span>
                            <span class="md:text-lg text-sm font-medium text-[#4f4f4f]"  [ngClass]="{'text-red-500': contactForm.get('tnc')?.value !== true && contactForm.get('tnc')?.touched || contactForm.get('tnc')?.value !== true && !isFormValid }">By clicking submit, you agree
                                to Kargone LLC’s&nbsp;</span><a [routerLink]="['/term-condition']"
                                class="underline text-[#1054b0] md:text-lg text-sm font-medium  hover:no-underline">Terms & Conditions</a><span class="md:text-lg text-sm font-medium text-[#4f4f4f]"
                                [ngClass]="{'text-red-500': contactForm.get('tnc')?.value !== true && contactForm.get('tnc')?.touched || contactForm.get('tnc')?.value !== true && !isFormValid }"> and </span><a [routerLink]="['/privacy-policy']"
                                class="underline text-[#1054b0] md:text-lg text-sm font-medium  hover:no-underline ">
                                Privacy Policy.</a>
                                <span class="md:text-lg text-sm font-medium text-[#4f4f4f]"  [ngClass]="{'text-red-500': contactForm.get('tnc')?.value !== true && contactForm.get('tnc')?.touched || contactForm.get('tnc')?.value !== true && !isFormValid }">&nbsp;
                                You consent to receive phone calls and SMS messages from Kargone LLC to provide updates and information regarding your business with Kargone LLC. Message frequency may vary. Message & data rates may apply. Reply STOP to opt-out of further messaging. Reply HELP for more information. See our Privacy Policy
                            </span>
                        </div>
                    </label>

                         <!-- SMS Checkbox -->
                    <!-- <label class="block mt-4 px-6 w-full h-auto checkbox border-none">
                        <div class="checkbox-custom">
                            <input type="checkbox"  formControlName="smsOptIn"
                                class="checked:bg-blue-500 mr-2 border-[#004AAD]" />
                            <span class="checkbox-box"></span>
                            <span class="md:text-lg text-sm font-medium text-[#4f4f4f]">Please check the box if you
                                would like to opt-in for our promotional content via SMS </span>
                        </div>
                    </label> -->

                    <!-- Email Checkbox -->
                    <!-- <label class="block mt-4 px-6 w-full h-auto checkbox border-none">
                        <div class="checkbox-custom">
                            <input type="checkbox" 
                            formControlName="emailOptIn"
                                class="checked:bg-blue-500 mr-2 border-[#004AAD]" />
                            <span class="checkbox-box"></span>
                            <span class="md:text-lg text-sm font-medium text-[#4f4f4f]">Please check the box if you
                                would like to opt-in for our promotional content via Email</span>
                        </div>
                    </label> -->
                        <div class="block text-center lg:text-left mt-4">
                            <button  type="submit" class="rounded-full bg-[#00449D] pt-3 pb-3 px-20 font-medium text-xl text-white  border border-transparent hover:border-[#004AAD] hover:bg-white hover:text-[#004AAD]">Submit</button>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
</section>
