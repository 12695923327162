import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  public isSeller:boolean = true;
  public user:any;
  public open:boolean = false;
    public faqArray:any 

  public dealerFaqArray:any = [
    {
      id:1,
      question: "Why do I have to use Kargone to buy used vehicles in the market?",
      answer: "Some of the reasons why dealerships choose to buy vehicles from Kargone:\n- Low fees\n- High-quality vehicles\n- It’s easy to buy used vehicles at Kargone\n- Huge inventory of used vehicles\n- You don’t have to advertise to buy a vehicle. Kargone does it for you"
    },
    {
      id:2,
      question: "What is the sign-up process?",
      answer: `It is a very simple process. All you have to do is
      click on the sign-up page and provide your business information. We will review your
      information and once approved integrate you into Kargone's verified dealer network.`
    },
    {
      id:3,
      question: "What is Kargone’s role in transactions?",
      answer: `We have a simple and effective role. All we do is
      make it easy for you to buy used vehicles in the market from private individuals.`
    },
    {
      id:4,
      question: "What factors do I need to consider before making an offer to a seller?",
      answer: `All our dealers make an offer to the seller based
      on the vehicle information provided by the seller. You can also factor in market conditions,
      demand and supply, economy, etc to determine the offer for a vehicle.`
    },
    {
      id:5,
      question: "Should I pick up the vehicle from the seller?",
      answer: `It is totally up to you. You can either choose to pick up the vehicle from the seller’s place or ask the seller to drop it off at the dealership.`
    },
    {
      id:6,
      question: "After I inspect the vehicle in person, can I revise my offer?",
      answer: `After the inspection, if you observed that the seller withheld some information or the damages are not specified accurately, you can offer a new price for the vehicle.`
    },
    {
      id:7,
      question: "How can I pay the seller?",
      answer: `You can pay the seller directly.`
    },
  ];
  
  public sellerFaqArray = [
    {
      id: 1,
      question: "Why should I use Kargone to sell my car?",
      answer: "At Kargone, you can get multiple offers from a network of Kargone-verified dealers and choose the best offer for your car in the comfort of your home. There is no need to contact or make multiple trips to the dealership. All you need is Kargone."
    },
    {
      id: 2,
      question: "Where can I find my VIN?",
      answer: "You can find your VIN on <br> - Your vehicle registration document <br> - inside your driver's door <br> - At the left bottom corner of your dashboard."
    },
    {
      id: 3,
      question: "Do I need to pay any fees to list my car on Kargone?",
      answer: "At Kargone, it is free for you to list and sell your car. There are no hidden charges or fees. Register at Kargone and get started selling your car."
    },
    {
      id: 4,
      question: "How does Kargone use my contact information?",
      answer: "Once you accept the offer from one of our verified dealers, your contact details will be shared with that particular dealer only. In addition to that, we will also share the dealer's contact information with you so that you can reach out to the dealer if you have any questions or concerns.<br/><br/>Only the dealer whose offer you have accepted will be able to see your contact information. Apart from that, we will not share your contact information with anyone. At Kargone, we take the privacy of our customers very seriously and take stringent measures to protect their data."
    },
    {
      id: 5,
      question: "What information do I need to provide about my car?",
      answer: "You need to provide basic information such as<br/>- VIN Number<br/>- Color of your car<br/>- Vehicle condition details<br/>- Finance or lease status<br/><br/>You need to log in to your Kargone account so that you can view these questions."
    },
    {
      id: 6,
      question: "What should I do if my car has any mechanical or electrical issues?",
      answer: "We're aware that certain issues can arise with cars from time to time and this could be one of the reasons why you want to sell your car. In our listing process, you will find a space where you can list the information about your vehicle's damages so that the dealers can make an informed decision about the offer. We aim to keep the information transparent for all our dealers and sellers.<br/><br/>Just because your car has some damage should not stop you from listing your car."
    },
    {
      id: 7,
      question: "On what basis does a dealer place an offer on my car?",
      answer: "The dealer makes an offer for your car only based on the information you have provided during the car listing process. The dealer would also use other third-party applications at his disposal to determine the price/offer of your car. The economic condition of the market would also come into play during the decision-making process. Rest assured that Our rigorous dealer onboarding and verification process consistently yields the best offers for our car sellers at Kargone. We recommend that you provide accurate information to receive the best price for your car."
    },
    {
      id: 8,
      question: "How do you find me the right dealer for my car?",
      answer: "Our advanced algorithm comprehends heaps of data points to ensure we only match you with the dealers that give you the highest offers for your car. Our algorithm matches you with up to 20 dealers based on your car's condition and other factors. We are committed to finding the ideal dealers who understand the car’s true value and offer a suitable selling price."
    },
    {
      id: 9,
      question: "Is it guaranteed that the dealer will pay me the offered price?",
      answer: "Yes, the dealer will pay the agreed price for your car provided the information you have supplied at the time of listing your vehicle is accurate. The only time where the dealers may not agree to pay the offered price is when the car details do not match the description provided by the seller, or if there is any withheld information with regard to the car damages.<br/><br/>Through our meticulous dealer onboarding procedure, we eliminate the inclusion of dealers who employ lowball tactics with car sellers, ensuring that only the top-tier dealers enter the market."
    },
    {
      id: 10,
      question: "How does Kargone maintain a high-quality dealer network?",
      answer: "Our team at Kargone reviews all dealer requests and understands how they conduct their business. Only if they meet the Kargone standard will they acquire Kargone verification. This is to ensure that car sellers get the best offers from the top dealers in their area. We always spend a lot of time ensuring dealers are adequate and qualified to use Kargone. Our aim is to guarantee a fantastic user experience for everyone using Kargone to sell their car."
    },
    {
      id: 11,
      question: "How much time will it take to close the deal?",
      answer: "Once you accept an offer from a dealer, you will instantly receive a scheduled request to either pick up the car from your place or drop off the car at the dealership. We notice that within 24 hours of an offer being accepted, the car is verified in person and purchased by the dealer."
    },
    {
      id: 12,
      question: "How and when would I receive the money?",
      answer: "The dealers will pay the money once they have inspected it in person."
    },
    {
      id: 13,
      question: "What kind of documents do I need in order to sell my car?",
      answer: "Title, registration, driver's license, car keys, and any other financial document related to the car."
    },
    {
      id: 14,
      question: "How do I proceed with DMV paperwork?",
      answer: "You do not have to worry about the DMV paperwork, the dealer will take care of it."
    },
    {
      id: 15,
      question: "How do you manage my lease or finances?",
      answer: "The dealer will arrange the payoff if you owe more than the offer, and you will have to pay the difference. If the loan is less than the offer, the dealer will offer you an equity cheque for the difference in amount."
    },
    {
      id: 16,
      question: "I don’t like my current offer, can I get an offer from another dealer?",
      answer: "Yes, you can re-list your car again and get an offer from another dealer. However, this option is only available if the first deal does not go through and the car is not as described."
    },
    {
      id: 17,
      question: "Who will inspect my car and where?",
      answer: "The dealer will inspect your vehicle upon arrival. You can choose to have an inspection at their dealership, your home, or a different location of your choice."
    },
    {
      id: 18,
      question: "What happens to my car tags or plates?",
      answer: "Your tags are your responsibility when the dealer hands them to you at the point of sale. This should be returned to the DMV."
    }
  ];
  
  

  constructor(private service:CommonService, @Inject(PLATFORM_ID) private platformId: any) {
  
    if (isPlatformBrowser(this.platformId)){
      this.user = JSON.parse(`${localStorage.getItem('user')}`);
    }
   
    this.service.scrollToTop()

    if(this.user && this.user.role == 0){
      this.isSeller = false;
      this.getFaq(1);
    }
    else{
      this.getFaq(0);
    }
  }

  openAccordions: number[] = [];

  toggleAccordion(id: number) {
    const index = this.openAccordions.indexOf(id);
    if (index !== -1) {
      // If accordion is open, close it
      this.openAccordions.splice(index, 1);
    } else {
      // If accordion is closed, open it
      this.openAccordions.push(id);
    }
  }

  isAccordionOpen(id: number): boolean {
    return this.openAccordions.includes(id);
  }
  
  getFaq(isDealer: number){
    this.service.showSpinner();
    try {
      this.service.getApi('faqs/getFaq?isDealer='+isDealer).subscribe((res:any)=>{
        this.faqArray = res.faq
      })
      this.service.hideSpinner();
    } catch (error) {
      this.service.hideSpinner();
    }
   
  }

}
